import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import HelpMeLogo from '../../assets/img/fundme150.png';
//walletconnect2
import SignClient from '@walletconnect/sign-client'
import { WalletConnectModal } from '@walletconnect/modal';
import { ElectrumNetworkProvider, Contract } from 'cashscript';
import { ElectrumCluster, ElectrumTransport } from 'cashscript/node_modules/electrum-cash';
import { useBlockchainContext } from '../Context/BlockchainContext';
import ArtifactCashStarter from '../../contracts/FundMeV8_Mainnet/json/CashStarter.json'
import ArtifactCashStarterManager from '../../contracts/FundMeV8_Mainnet/json/CashStarterManager.json';
import ArtifactCashStarterRefund from '../../contracts/FundMeV8_Mainnet/json/CashStarterRefund.json';
interface MyWindow extends Window {
  googleTranslateElementInit: () => void;
}
declare let window: MyWindow;

declare namespace google {
  namespace translate {
    class TranslateElement {
      constructor(options: any, container: string);
    }
  }
}
type NetworkType = "mainnet" | "testnet4" | "chipnet";

function Header() {
  const [currentPage, setCurrentPage] = useState('Campaigns');
  const { setBlockchainData } = useBlockchainContext();
  const { walletConnectSession, walletConnectInstance, electrumServer, electrumCluster, usersAddress, connectedChain } = useBlockchainContext();
  //initial context setup variables
  //const [instance, setInstance] = useState<SignClient>();
  const [network, setNetwork] = useState<NetworkType>('mainnet');
  const [contractsOK, setContractsOK] = useState(false);
  const [isElectrumSetup, setIsElectrumSetup] = useState(false);

  // Function to toggle the current page if needed
  const togglePage = (page: string) => {
    setCurrentPage(page);
  };

  //////////Compile an artifact into a CashScript Contract object
  async function compileContract(contractArtifact: any, args: any[]) {
    const contract = new Contract(contractArtifact, args, {provider: electrumServer, addressType: 'p2sh32'});
    return contract;
  }

//////////////////////////////////////////////////
////////// Initial UseEffect: Initialize Contracts on page load
//////////////////////////////////////////////////
  useEffect(() => {
    async function checkAndCompileContracts() {
      if (contractsOK === false) {
        try {
          const compiledCashStarter = await compileContract(ArtifactCashStarter, []);
          const compiledCashStarterManager = await compileContract(ArtifactCashStarterManager, []);
          const compiledCashStarterRefund = await compileContract(ArtifactCashStarterRefund, []);

          setBlockchainData({
            contractCashStarter: compiledCashStarter,
            contractManager: compiledCashStarterManager,
            contractCashStarterRefund: compiledCashStarterRefund
          });

          setContractsOK(true);
          console.log('contracts compiled');

        } catch (error) {
          console.log('contract compiling error: ' + error);
        }
      }
    }

    checkAndCompileContracts();
  }, []);

//////////////////////////////////////////////////
//////////Create connection to the blockchain
//////////////////////////////////////////////////
  const setupElectrum = async () => {
      if (network == 'chipnet') {
        const newElectrumCluster = new ElectrumCluster('ChipnetCluster', '1.5.1', 1, 1);
        newElectrumCluster.addServer('chipnet.imaginary.cash', ElectrumTransport.WS.Port, ElectrumTransport.WS.Scheme);
        const newElectrumServer = new ElectrumNetworkProvider(network, newElectrumCluster);
        setBlockchainData({ electrumServer: newElectrumServer, electrumCluster: newElectrumCluster, connectedChain: 'bch:bchtest' });
        setIsElectrumSetup(true);
        console.log('electrum connected to: ' + newElectrumCluster.application);

      } else if (network == 'mainnet') {
        const newElectrumCluster = new ElectrumCluster('MainnetCluster', '1.5.1', 1, 1, 0, 3000, 6000);
        newElectrumCluster.addServer('electrum.imaginary.cash', ElectrumTransport.WSS.Port, ElectrumTransport.WSS.Scheme);
        //newElectrumCluster.addServer('fulcrum.greyh.at', ElectrumTransport.WSS.Port, ElectrumTransport.WSS.Scheme);
        //newElectrumCluster.addServer('fulcrum.jettscythe.xyz', ElectrumTransport.WSS.Port, ElectrumTransport.WSS.Scheme);
        const newElectrumServer = new ElectrumNetworkProvider(network, newElectrumCluster);
        setBlockchainData({ electrumServer: newElectrumServer, electrumCluster: newElectrumCluster, connectedChain: 'bch:bitcoincash' });
        setIsElectrumSetup(true);
        console.log('electrum connected to: ' + newElectrumCluster.application);
      }
  }

////////////////////////////////////////////////// 
////////// Wallet Connect V2
//////////////////////////////////////////////////
  //connection settings
  const signClient = async () => {
    return await SignClient.init({
      projectId: '6d55df615354645451afe022bb4cef8f',
      // optional parameters
      relayUrl: 'wss://relay.walletconnect.com',
      metadata: {
        name: 'FundMe.Cash',
        description: 'BitcoinCash Crowdfunding',
        url: 'https://fundme.cash',
        icons: ['https://fundme.cash/fundme150w.png']
      }
    });
  } 

  const setupSignClient = async () => {
    // Setup walletconnect client
    if (walletConnectInstance == null) {
      const client = await signClient();  //call connection with above settings, wait for instance response
      if (client != null) {

      // Set listeners on client then save client to state
        console.log('setupSignClient(): walletConnectInstance detected, adding listeners...')
        // Attach event listeners to client instance
        client.on('session_event', ( event ) => {
          console.log(event);
        });

        client.on('session_update', ({ topic, params }) => {
          console.log(params);
        });

        client.on('session_delete', () => {
          console.log('Deleting session with topic ' + walletConnectSession.topic);
          client.session.delete(
            walletConnectSession.topic,
            {
              code: 6000,
              message: "User Disconnected",
            })
            setBlockchainData({ usersAddress: '' });
        });

        setBlockchainData({ instance: client });

       //check if session exists in localstorage to use
       console.log('setupSession(): checking for existing walletconnect session...')
       const lastKeyIndex = client.session.getAll().length - 1;
       const lastSession = client.session.getAll()[lastKeyIndex];

       if (lastSession) {
         console.log('setupSession(): session found in localstorage: ');
         console.log(lastSession);

         //set users saved address
         const existingSessionAddress = lastSession.namespaces['bch'].accounts[0].slice(4);
         console.log(existingSessionAddress);
         setBlockchainData({ session: lastSession, usersAddress: existingSessionAddress });
       }
      }
    }
  }

//////////////////////////////////////////////////
////////// User-triggered blockchain connect request
//////////////////////////////////////////////////
// Setup Session saved from localstorage
const manualSetupSignClient = async () => {
  // Setup walletconnect client
  let client: SignClient;
  if (walletConnectInstance == null) {
    client = await signClient();  //call connection with above settings, wait for instance response
    if (client != null) {

      // Set listeners on client then save client to state
      console.log('manualSetupSignClient(): walletConnectInstance detected, adding listeners...')
      // Attach event listeners to client instance
      client.on('session_event', ( event ) => {
        //console.log('session_event');
        console.log(event);
      });
      //console.log('session_event added...');

      client.on('session_update', ({ topic, params }) => {
        //console.log('session_update');
        console.log(params);
      });
      //console.log('session_update added...');

      client.on('session_delete', () => {
        console.log('Deleting session with topic ' + walletConnectSession.topic);
        client.session.delete(
          walletConnectSession.topic,
          {
            code: 6000,
            message: "User Disconnected",
          })
      });

      setBlockchainData({ instance: client });
    } 
  }

  //load saved session or start a new one
  console.log('manualSetupSignClient(): checking for existing walletconnect session...');
  const lastKeyIndex = walletConnectInstance.session.getAll().length - 1;
  const lastSession = walletConnectInstance.session.getAll()[lastKeyIndex];

  if (lastSession) {
    console.log('manualSetupSignClient(): session found in localstorage');
    //setSession(lastSession);
    setBlockchainData({ session: lastSession });

    //set users saved address
    const existingSessionAddress = lastSession.namespaces['bch'].accounts[0].slice(4);
    console.log(existingSessionAddress);
    //setAddress(existingSessionAddress);
    setBlockchainData({ usersAddress: existingSessionAddress });

  //otherwise launch QR to start a new session
  } else {
    console.log('manualSetupSignClient(): no existing walletconnect session found, making a new one...');
    const { uri, approval } = await walletConnectInstance.connect({ requiredNamespaces });
    console.log('manualSetupSignClient(): uri received:');
    console.log(uri);
  
    // Open login window
    await walletConnectModal.openModal({ uri });
  
    // Await session approval from the wallet.
    console.log('manualSetupSignClient(): waiting for approval');
    const session = await approval();
    setBlockchainData({ session: session });

    console.log('manualSetupSignClient(): WC connected! Session:');
    console.log(session);

    const existingSessionAddress = session.namespaces['bch'].accounts[0].slice(4);
    setBlockchainData({ usersAddress: existingSessionAddress });

    // Close the QRCode modal in case it was open.
    walletConnectModal.closeModal();
  }
}
//////////////////////////////////////////////////
////////// Initialize Electrum, WalletConnect, userAddress
//////////////////////////////////////////////////
const initBlockchain = async () => {
  try {
    if (!walletConnectInstance) {
      console.log('initBlockchain(): 1. walletconnect NOT detected, do setupSignClient()');
      try {
        await setupSignClient();
      } catch (error) {
        console.error('initBlockchain(): Error setting up wallet connect:', error);
      }
    }

    if (!electrumServer) {
      console.log('initBlockchain(): 3. electrumServer not detected, do setupElectrum()');
      try {
        await setupElectrum();
      } catch (error) {
        console.error('initBlockchain(): Error setting up electrum:', error);
      }
    }
  } catch (error) {
    console.error('initBlockchain(): Error in blockchain initialization:', error);
  }
}

//////////////////////////////////////////////////
////////// Initial Pageload: initialize blockchain stuff
//////////////////////////////////////////////////
  useEffect(() => {
    if (!walletConnectSession) {
      console.log('header useEffect doesnt detect session');
      initBlockchain();
    }
  }, []);

//////////////////////////////////////////////////
////////// Prepare Wallet Connect Modal
//////////////////////////////////////////////////
  const walletConnectModal = new WalletConnectModal({
    projectId: '423bfeef86a795e9525d6013df14db7d',
    themeMode: 'dark',
    themeVariables: {
      '--wcm-background-color': '#20c997',
      '--wcm-accent-color': '#20c997',
    },
    explorerExcludedWalletIds: 'ALL',
  });

  const requiredNamespaces = {
    bch: {
      chains: [connectedChain],
      methods: ['bch_getAddresses', 'bch_signTransaction', 'bch_signMessage'],
      events: ['addressesChanged'],
    },
  };  

const handleDisconnectWC = () => {
  console.log('handleDisconnectWC() started');
  try {
    if (walletConnectInstance && walletConnectSession) {
      walletConnectInstance?.disconnect({
        topic: walletConnectSession.topic,
        reason: {
          code: 6000,
          message: "User Disconnected",
        }
      });

      localStorage.clear();
      localStorage.removeItem(walletConnectSession.topic);
      setBlockchainData({ usersAddress: '' });
      console.log('Wallet Disconnected');
    }

    let lastKeyIndex: number;
    let lastSession: any;

    if (walletConnectInstance) {
      console.log('walletConnectInstance exists')
      lastKeyIndex = walletConnectInstance.session.getAll().length - 1;
      lastSession = walletConnectInstance.session.getAll()[lastKeyIndex];
    }// else if (instance) {
    //  console.log('instance exists')
    //  lastKeyIndex = instance.session.getAll().length - 1;
    //  lastSession = instance.session.getAll()[lastKeyIndex];
    //}

    if (lastSession) {
      console.log('detected lastSession, deleting...')
      console.log('lastSession: ');
      console.log(lastSession);
      localStorage.removeItem(lastSession);
    }

  } catch (error) {
    console.log('Error disconnecting:', error);
  }
}

useEffect(() => {
  if (!document.querySelector('script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]')) {
    const googleTranslateScript = document.createElement('script');
    googleTranslateScript.type = 'text/javascript';
    googleTranslateScript.async = true;
    googleTranslateScript.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    (document.getElementsByTagName('head')[0] || document.documentElement).appendChild(googleTranslateScript);
  }
  window.googleTranslateElementInit = function() {
    new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
  };
}, []);

    return (
      <StyledHeader>

        <StyledGroup>
          <StyledSectionTitle>
            <StyledLink to="/">
              <StyledTitle>FundMe.<span className="cash">Cash</span></StyledTitle>
            </StyledLink>
            <StyledLogo />
          </StyledSectionTitle>

          <StyledMenu>
            <StyledSection1>
                <StyledLink to="/" onClick={() => togglePage('Campaigns')}>
                  <MenuButton isactive={currentPage === 'Campaigns'}>Campaigns</MenuButton>
                </StyledLink>

                <StyledLink to="/Create" onClick={() => togglePage('Create')}>
                  <MenuButton isactive={currentPage === 'Create'}>Create</MenuButton>
                </StyledLink>

                <StyledLink to="/Tasks" onClick={() => togglePage('Tasks')}>
                  <MenuButton isactive={currentPage === 'Tasks'}>Tasks</MenuButton>
                </StyledLink>

                <StyledLink to="/FAQ" onClick={() => togglePage('FAQ')}>
                  <MenuButton isactive={currentPage === 'FAQ'}>FAQ</MenuButton>
                </StyledLink>

                <StyledLink to="/Rules" onClick={() => togglePage('Rules')}>
                  <MenuButton isactive={currentPage === 'Rules'}>Rules</MenuButton>
                </StyledLink>

            </StyledSection1>
            
            <StyledSection2>
              {usersAddress == '' &&
                <MenuButton onClick={manualSetupSignClient}>Connect</MenuButton>
              }

              {usersAddress ? (
                <StyledWalletButtons>
                  <StyledLink to="/Wallet" onClick={() => togglePage('Wallet')}>
                    <WalletButton disabled={false} isactive={currentPage === 'Wallet'}>Wallet</WalletButton>
                  </StyledLink>
                  <DisconnectButton onClick={handleDisconnectWC}>Disconnect</DisconnectButton>
                </StyledWalletButtons>
                ) : (
                  <></>
              )}

              {usersAddress != '' ? (
                <StyledUserAddress>
                  Connected: <span style={{ color: '#0ddaa1' }}>{`${usersAddress.substring(12, 22)} ... ${usersAddress.substring(usersAddress.length - 10)}`}</span>
                </StyledUserAddress>
                ) : (
                  <></>
              )}

            </StyledSection2>

          </StyledMenu>
        </StyledGroup>

        <StyledTranslate>
            <div id="google_translate_element"></div>
        </StyledTranslate>

    </StyledHeader>
);
}


interface MenuButtonProps {
  isactive?: boolean;
}

const StyledLink = styled(Link)`
  text-decoration: none;
`;
const StyledHeader = styled.div`
  display: flex;
  background-color: black;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 24px;
  border-bottom: 1px solid #0AC18E;
  height: 135px;
  justify-content: center;
  z-index: 0;
`
const StyledGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
`
const StyledWalletButtons = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  z-index: 2;
`
const StyledTitle = styled.div`
  color: white;
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.8);
  .cash {
    color: #0AC18E;
  }
`
const StyledUserAddress = styled.div`
  position: absolute;
  color: white;
  width: 100%;
  height: 20px;
  top: 47px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.9);
`
const StyledDisconnectButton = styled.div`
  color: white;
  width: 100px;
  height: 35px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  z-index: 3;
`
const StyledLogo = styled.div`
  background-image: url(${HelpMeLogo});
  text-align: center;
  width: 130px;
  height: 150px;
  right: -130px;
  top: 10px;
  position: absolute;
  z-index: 10;
`
const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: -5px;
  left: 0px;
  gap: 7px;
  border: 0px solid red;
`
const StyledTranslate = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  right: 15px;
  z-index: 15;
`
const MenuButton = styled.button<MenuButtonProps>`
  background-color: ${(props) => (props.isactive ? '#09af81' : '#000')};
  color: #fff;
  padding: 5px 5px;
  border: 2px outset ${(props) => (props.isactive ? '#fff' : '#0AC18E')};
  border-radius: 0px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 6px 6px 5px rgba(0,0,0,0.6);
  &:hover {
    background-color: ${(props) => (props.isactive ? '#09af81' : '#004295')};
  }
`
const DisconnectButton = styled.button`
  position: relative;
  top: 2px;
  background-color: #000;
  color: #fff;
  padding: 5px 5px;
  height: 34px;
  border: 2px solid #0AC18E;
  border-left: 0px;
  border-radius: 0px;
  font-size: 18px;
  cursor: pointer;
  opacity: 1.0;
  box-shadow: 6px 6px 5px rgba(0,0,0,0.6);
  &:hover {
    background-color: #d30000;
  }
`
const WalletButton = styled.button<MenuButtonProps>`
  background-color: ${(props) => (props.isactive ? '#09af81' : '#000')};
  color: #fff;
  padding: 5px 5px;
  width: 150px;
  border: 2px outset ${(props) => (props.isactive ? '#fff' : '#0AC18E')};
  border-radius: 0px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 6px 6px 5px rgba(0,0,0,0.6);
  &:hover {
    background-color: ${(props) => (props.isactive ? '#09af81' : '#004295')};
  }
`
const StyledSectionTitle = styled.div`
  position: relative;
  top: 0px;
  border: 0px solid white;
  display: flex;
  gap: 5px;
`;
const StyledSection1 = styled.div`
  position: relative;
  border: 0px solid blue;
  display: flex;
  gap: 7px;
`;
const StyledSection2 = styled.div`
  position: relative;
  justify-content: center;
  border: 0px solid blue;
  display: flex;
  gap: 5px;
`;
export default Header;