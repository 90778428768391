import React from 'react';
import styled from 'styled-components';

interface ModalTaskProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const ModalTask: React.FC<ModalTaskProps> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <Overlay onClick={onClose}>
            <ModalContainer onClick={e => e.stopPropagation()}>
                {children}
            </ModalContainer>
        </Overlay>
    );
};

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;
const ModalContainer = styled.div`
    background: linear-gradient(145deg, #002917 0%, #000 100%);
    border: 2px solid #0ac18e;
    border-radius: 10px;
    min-width: 300px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
`;

export default ModalTask;