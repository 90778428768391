import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import Sidebar from '../../components/SideBar'; 
import ErrorNotification from '../../components/ErrorNotification';
import { AddressCashStarter, MasterCategoryID } from '../../constants/values'
import ArtifactCashStarter from '../../contracts/FundMeV8_Mainnet/json/CashStarter.json';
import { Utxo, Contract } from 'cashscript';
import Spinner from '../../assets/img/spinner.gif';
import { useBlockchainContext } from '../../components/Context/BlockchainContext';
import BCHLogo from '../../assets/img/bch.png';
import axios, { AxiosError } from 'axios';
import CasualLogo from '../../assets/img/casualLogo.png';
import ImageSpinner from '../../assets/img/BCH2.gif';
import { environmentUrl } from '../../constants/environment';
import ArtifactCashStarterRefund from '../../contracts/FundMeV8_Mainnet/json/CashStarterRefund.json';
import cashStarterRefund from '../../functions/cashstarterRefund';
import { stringify } from '@bitauth/libauth';

interface CampaignData {
  id: number;
  name: string;
  owner: string;
  description: string;
  logo: string;
  banner: string;
  pledges: any[]; // You might want to define a more specific type for pledges
}

const Wallet = () => {
  const [pledges, setPledges] = useState<Utxo[]>([]);
  const [refundablePledges, setRefundablePledges] = useState<Utxo[]>([]);
  const [activeCampaigns, setActiveCampaigns] = useState<Utxo[]>([]);
  //const [campaignData, setCampaignData] = useState<CampaignData[]>([]);
  const [campaignData, setCampaignData] = useState<{ [key: number]: CampaignData }>({});
  const { walletConnectSession, walletConnectInstance, electrumServer, electrumCluster, usersAddress, connectedChain, contractCashStarter, contractCashStarterRefund } = useBlockchainContext();
  const [error, setError] = useState('');
  const [contractsOK, setContractsOK] = useState(false);
  const [refundPending, setRefundPending] = useState(false);
  const [selectedNFT, setSelectedNFT] = useState<Utxo | null>();
  const selectedNFTRef = useRef<Utxo | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalAvailableSats, setTotalAvailableSats] = useState<bigint>(0n);

  //////////Convert hex to big-endian and then to decimal
  const hexLEToDecimal = (hex: string): number => { 
    const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
    return parseInt(bigEndianHex, 16);
  };
  //////////Satoshis to BCH
  const formatSatoshiToBCH = (satoshis: bigint) => {
    const bch = Number(satoshis) / 100000000;
    return bch.toFixed(8);
  };

  //////////Handle user selecting receiptNFT
  const handleSetSelectedNFT = useCallback((utxo: Utxo) => {
    setSelectedNFT(utxo);
    selectedNFTRef.current = utxo;
  }, []);

//////////////////////////////////////////////////
////////// Initial UseEffect: Get pledgeNFT's that can be refunded
//////////////////////////////////////////////////
useEffect(() => {
  async function getPledgeUTXOs() {
    if (!electrumServer || usersAddress == '' || contractCashStarter == undefined) return;
    //delay to allow electrum to stabilize
    setTimeout(async () => {
      //get all pledgeNFTs in users wallet
      const userUTXOs: Utxo[] = await electrumServer.getUtxos(usersAddress);
      console.log(userUTXOs);

      //get total amount of sats in users wallet (initial address) that do not have a token
      const totalSats = userUTXOs.reduce((sum: bigint, utxo: Utxo) => {
        if (!utxo.token) {
          return sum + utxo.satoshis;
        }
        return sum;
      }, 0n);
      console.log('totalSats: ', totalSats);
      setTotalAvailableSats(totalSats);

      //get all pledgeNFTs in users wallet
      const pledgeNFTs = userUTXOs.filter( 
        utxo => utxo.token?.category == MasterCategoryID //only CashStarter NFT's
          && utxo.token?.nft?.capability == 'none' //only immutable ones
      );
      setPledges(pledgeNFTs);

      //get all campaignNFTs on CashStarter contract
      const cashStarterUTXOs: Utxo[] = await electrumServer.getUtxos(contractCashStarter.address);
      const activeCampaigns = cashStarterUTXOs.filter( 
        utxo => utxo.token?.category == MasterCategoryID //only CashStarter NFT's
          && utxo.token?.nft?.capability == 'minting' || 'mutable' //only active or stop'd campaigns
      );
      setActiveCampaigns(activeCampaigns);

      // Find matching UTXOs
      const matchingUTXOs = pledgeNFTs.filter(pledgeNFT => {
        const pledgeCampaignID = pledgeNFT.token?.nft?.commitment.substring(70, 80);
        return activeCampaigns.some(campaign => campaign.token?.nft?.commitment.substring(70, 80) === pledgeCampaignID);
      });
      console.log('refundables: ');
      console.log(matchingUTXOs);
      setRefundablePledges(matchingUTXOs);
      setIsLoading(false);

      // Fetch campaign metadata
      matchingUTXOs.forEach(async (campaign: Utxo) => {
        const campaignId = hexLEToDecimal(campaign.token?.nft?.commitment!.substring(70, 80)!);
        try {
          const info = await fetchCampaign(campaignId);
          setCampaignData(prev => ({ ...prev, [campaignId]: info }));
        } catch (error) {
          console.error(`Error fetching campaign ${campaignId}:`, error);
        }
      });

    }, 2500); // Delay of 2.5 seconds
  }

  getPledgeUTXOs();
}, [electrumServer, contractCashStarter, usersAddress]);

//////////////////////////////////////////////////
////////// Fetch campaign metadata from server
////////////////////////////////////////////////// 
async function fetchCampaign(id: number): Promise<CampaignData> {
  try {
    const url = environmentUrl + `/get-campaign/` + id;

    const response = await axios.get<CampaignData>(url);
    return response.data;

  } catch (error) {
    console.error('Fetch campaign error:', error);
    throw error;
  }
}

async function signTransaction(options: any) {
  console.log('signing transaction...');
  try {
      if (walletConnectInstance) {
      const params = JSON.parse(stringify(options));
      console.log('wc params:');
      console.log(params);
      const result = await walletConnectInstance.request({
          chainId: connectedChain,
          topic: walletConnectSession.topic,
          request: {
          method: "bch_signTransaction",
          params: params,
          },
      });
      return result;
      }
  } catch (error) {
      console.log('signTransation error: ' + error);
      return undefined;
  }
}

// Function to forward users pledge to the contract function
const handleRefund = async (campaignIDNum: number, selectedNFT: Utxo) => {
  console.log('handleRefund camapignID: ', campaignIDNum);
  setRefundPending(true);

  if (electrumServer && campaignIDNum && selectedNFT) {
    const campaignID = selectedNFT.token?.nft?.commitment.substring(70, 80) ?? "0";

    if (campaignIDNum == hexLEToDecimal(campaignID)) {
      const pledgeID = selectedNFT.token?.nft?.commitment.substring(62, 70) ?? "0";

      const signResult = await cashStarterRefund({electrumServer, usersAddress, contractCashStarter, contractCashStarterRefund, campaignID, selectedNFT, signTransaction, setError});
      if (signResult != undefined) {
        const rawTx = signResult.signedTransaction;
        console.log('signedTransaction from walletconnect: ');
        console.log(signResult);

        try {
          const result = await electrumServer.sendRawTransaction(rawTx);
          console.log('Broadcasted, txid: ' + result);

          const decimalCampaignID = hexLEToDecimal(campaignID);
          const decimalPledgeID = hexLEToDecimal(pledgeID);

          // Send pledge deletion notice to external server
          await axios.delete(environmentUrl + '/delete-pledge', { data: { campaignID: decimalCampaignID, pledgeID: decimalPledgeID, txid: result } });
          setError(`Refund submitted. TxID:\n${result}`);
        } catch (error) {
          console.error('Error refunding:', error);
          setError(`Error refunding: \n${error}`);
        }
      }
    }

  } else {
    console.log('Error refunding. Did you select the correct PledgeNFT?');
    setError(`Error refunding. Is your wallet connected?`);
  }

  setRefundPending(false);

}

//////////////////////////////////////////////////
////////// Return PledgeNFT UTXO
//////////////////////////////////////////////////
const NFTItem: React.FC<{ utxo: Utxo }> = ({ utxo }) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);

  function handleClick() {
    setIsSelected(current => !current);
    handleSetSelectedNFT(utxo);
  }

  return (
    <StyledNFT onClick={handleClick} isSelected={isSelected}>
      <StyledNFTTitle>
        Pledge #{hexLEToDecimal(utxo.token?.nft?.commitment.substring(62, 70) ?? "0")}
      </StyledNFTTitle>
      <StyledSats>
        <StyledPledgeLogo size={20}/> {formatSatoshiToBCH(BigInt(hexLEToDecimal(utxo.token?.nft?.commitment.substring(0, 11) ?? "0")))}
      </StyledSats>
    </StyledNFT>
  );
};

  return (
    <Container>
      <StyledBackground />
      <ErrorNotification message={error} setError={setError} />

      <StyledRowNFTs>
        <StyledBlack />
        <StyledBalance>
          Your wallet balance FundMe can see: {formatSatoshiToBCH(totalAvailableSats)}
        </StyledBalance>

        {isLoading ?
          <Column>
            <StyledTitle>Your Refundable Pledges</StyledTitle>
            <StyledSpinner />
          </Column> 
          :
          <Column>
            <StyledTitle>No refundable pledges found</StyledTitle>
          </Column>
        }
          
        {Object.values(campaignData).map((campaign) => {
          if (refundablePledges.length === 0) {
            return null; // Skip rendering if there are no matching pledges
          }

          return (
            <Column key={campaign.id}>
              <Row>
                <StyledTitle>{campaign.name}</StyledTitle>
                <UserName>{campaign.owner}</UserName>
              </Row>
              <Row>
                <BannerImage logoUrl={campaign.banner} />
                <LogoImage logoUrl={campaign.logo} />
              </Row>

              <MenuButton disabled={campaign.id != hexLEToDecimal(selectedNFT?.token?.nft?.commitment.substring(70, 80) ?? "0")} width={125} onClick={() => handleRefund(campaign.id, selectedNFT!)}>
                {refundPending ? (
                  <StyledButtonSpinner />
                ) : (
                  'Refund'
                )}
              </MenuButton>

                {refundablePledges.map((utxo, index) => {
                  const utxoCampaignId = hexLEToDecimal(utxo.token?.nft?.commitment.substring(70, 80) ?? "0");
                  if (campaign.id == utxoCampaignId) {
                    return <NFTItem key={index} utxo={utxo} />;
                  }
                  return null;               
                })}

            </Column>
          );
        })}


        <StyledRefundButtons>
        {/*   <MenuButton disabled={!selectedNFT} width={90} onClick={() => handleRefund(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0", selectedNFT!)}>
            {refundPending ? (
              <StyledButtonSpinner />
            ) : (
              'Refund'
            )}
          </MenuButton> */}
        </StyledRefundButtons>

      </StyledRowNFTs>
      
    </Container>
  );
};

interface LogoDivProps {
  logoUrl: string;
}
interface MenuButtonProps {
  isactive?: boolean;
  width: number;
}
interface StyledNFTProps {
  isSelected: boolean;
}
interface StyledLogoProps {
  size: number;
}

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: #000;
  a {
    color: #0AC18E;
    text-decoration: none;
    &:hover {
      color: #09af81;
    }
  }
`;
const StyledBackground = styled.div`
  background-image: url(${CasualLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  display: flex;
  height: 100vh;
  width: 100%;
  border: 0px solid red;
  z-index: 0;
  opacity: 0.4;
`;
const StyledBalance = styled.div`
  width: 100%;
  height: 40px;
  border: 0px solid red;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  left: 0px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
  text-align: center;
  padding-top: 25px;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
  @media (max-width: 800px) {
    font-size: 14px;
  }
`;
const StyledTitle = styled.div`
  width: 100%;
  height: 40px;
  border: 0px solid red;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  left: 0px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
  text-align: center;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
  @media (max-width: 800px) {
    font-size: 14px;
  }
`;
export const StyledDiscountButton = styled.button<{ discountApplied: boolean }>`
  background-color: ${(props) => (props.discountApplied ? '#0AC18E' : '#f0f0f0')};
  border: 1px solid #0AC18E;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  width: 20px;
  height: 20px;

 &::after {
    content: '${(props) => (props.discountApplied ? "\\2713" : "")}'; // Unicode checkmark
    color: white;
    font-size: 20px;
    position: absolute;
    transform: translate(-50%, -55%);
  }
`;
const StyledButtonSpinner = styled.div`
  position: relative;
  background-image: url(${Spinner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  display: flex;
  border: 0px solid red;
  z-index: 10;
`;
const StyledBlack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.7;
  background: #000;
`;
const StyledRefundButtons = styled.div`
  position: absolute;
  left: 0px;
  top: 25px;
  width: 110px;
  height: 90px;
  display: flex;
  flex-direction: column;
  border: 0px solid orange;
  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    width: 150px;
  }
  @media (max-width: 670px) {
    width: 120px;
    min-width: 120px;
  }
`;
const MenuButton = styled.button<MenuButtonProps>`
  position: relative;
  display: flex;
  background-color: ${(props) => (props.isactive ? '#09af81' : '#0AC18E')};
  color: #F8F8F8;
  border: 1px solid #fff;
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
  width: ${props => props.width}px;
  min-width: 50px;
  height: 32px;
  top: 7px;
  margin-bottom: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
  &:hover {
    background-color: #0cd9a0;
    border: 2px solid #fff;
  }
  @media (max-width: 1000px) {
    width: 125px;
  }
  &:disabled {
    background: #aaa;
    cursor: not-allowed;
    border: 2px solid #aaa;
    width: 125px;
  }
`
const StyledNFTContainer = styled.div`
  position: relative;
  top: 5px;
  right: 0px;
  display: flex;
  width: 235px;
  height: 92px;
  flex: 1 1 auto;
  flex-wrap: wrap;
  border-radius: 10px;
  border: 1px solid red;
  border-radius: 0px;
  overflow-x: auto;
  overflow-y: auto;
  flex-direction: row;
  align-items: flex-start;
  @media (max-width: 1000px) {
    width: 70%;
    justify-content: center;
  }
  @media (max-width: 670px) {
    width: 60%;
    min-width: 60%;
  }
`;
const StyledRowNFTs = styled.div`
  position: relative;
  display: flex;
  min-width: 350px;
  width: 40%;
  flex: 1 1 auto;
  flex-direction: column;
  flex-wrap: wrap;
  border: 0px;
  border-left: 2px solid #0AC18E;
  border-right: 2px solid #0AC18E;
  height: 120px;
`;
const StyledNFT = styled.div<StyledNFTProps>`
  position: relative;
  background-color: ${(props) => (props.isSelected ? '#0AC18E' : 'transparent')};
  color: white;
  padding: 0px;
  display: flex;
  flex-direction: row;
  width: 250px;
  height: 30px;
  margin-top: 5px;
  border: 2px solid  ${(props) => (props.isSelected ? '#fff' : '#0AC18E')};
  border-radius: 0px;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 5px rgba(255,255,255,0.3);
  margin-bottom: 5px;
  &:hover {
    background-color: #0AC18E;
    border: 2px solid #fff;
  }
  @media (max-width: 670px) {
    width: 200px;
  }
`;
const StyledNFTTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 0px solid yellow;
  height: 20px;
  padding-left: 5px;
  padding: 0px;
  text-decoration: none;
  color: #F8F8F8;
  @media (max-width: 670px) {
    font-size: 12px;
  }
`;
const StyledSats = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  margin-left: 10px;
  left: 100px;
  color: white;
  border: 0px solid red;
  font-size: 14px;
  font-weight: 600;
  @media (max-width: 670px) {
    font-size: 12px;
  }
`;
const StyledPledgeLogo = styled.div<StyledLogoProps>`
  position: relative;
  background-image: url(${BCHLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  display: flex;
  border: 0px solid red;
`;
const Column = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #0AC18E;
  justify-content: center;
  align-items: center;
  @media (max-width: 670px) {
    width: 100%;
    justify-content: center;
  }
`;
const Row= styled.div`
  display: flex;
  flex-direction: row;
  border: 0px solid red;
  justify-content: center;
  width: 100%;
`;
const BannerImage = styled.div<LogoDivProps>`
  background-image: url(${props => props.logoUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  width: 100%;
  max-width: 500px;
  border: 0px solid #0AC18E;
  box-shadow: 7px 7px 5px rgba(0,0,0,0.4);
  min-height: 100px;
`;
const LogoImage = styled.div<LogoDivProps>`
  background-image: url(${props => props.logoUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  width: 100%;
  max-width: 120px;
  border: 0px solid #0AC18E;
`;
const UserName = styled.div`
  color: #F8F8F8;
  height: 40px;
  width: 180px;
  font-size: 1.0em;
  font-weight: 600;
  border: 0px solid red;
  overflow: hidden;
  @media (max-width: 1000px) {
    font-size: 0.8em
  }
  @media (max-width: 670px) {
    position: absolute;
    width: 250px;
    height: 15px;
    top: 20px;
    right: 5px;
    font-size: 0.7em;
    text-align: right;
  }
`;
const StyledSpinner = styled.div`
  position: relative;
  background-image: url(${ImageSpinner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 150px;
  width: 150px;
  display: flex;
  border: 1px solid #0AC18E;
  z-index: 100;
`;
export default Wallet;
