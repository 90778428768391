import React, { FC, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Rules from './Pages/Rules'
import Campaigns from './Pages/Campaigns';
import Create from './Pages/Create';
import FAQ from './Pages/FAQ';
import Tasks from './Pages/Tasks';
import Wallet from './Pages/Wallet';
import { BlockchainProvider } from './components/Context/BlockchainContext';
import CampaignDetail from './components/CampaignDetail/CampaignDetail';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function injectWalletConnectStyles() {
  const style = document.createElement('style');
  style.textContent = `
    wcm-modal {
      position: relative !important;
      z-index: 10000 !important;
    }
    wcm-modal-backcard {
      position: fixed !important;
      z-index: 9999 !important;
    }
    [class^="wcm-"],
    [class*=" wcm-"] {
      position: relative !important;
      z-index: 9998 !important;
    }
  `;
  document.head.appendChild(style);
}

const App: FC = () =>  {
  useEffect(() => {
    injectWalletConnectStyles();
  }, []);

  return (
    <BlockchainProvider>
      <Router>
        <div className="App">
        {/* <Warning /> */}
          <Header />

          <Routes>
            <Route path="/" element={<Campaigns />} />
            <Route path="/Create" element={<Create />} />
            <Route path="/FAQ" element={<FAQ />} />
            <Route path="/Rules" element={<Rules />} />
            <Route path="/Tasks" element={<Tasks />} />
            <Route path="/Wallet" element={<Wallet />} />
            <Route path="/campaign/:id" element={<CampaignDetail />} />

          </Routes>
          <ToastContainer
            position="bottom-right"
            autoClose={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            theme="dark"  // Add this line
            style={{
              // Optional: Add custom styles
              '--toastify-color-info': '#2BB67B',
              '--toastify-text-color-info': '#000000',
            } as React.CSSProperties}
          />
        </div>
        <Footer />
      </Router>
    </BlockchainProvider>
  );
}
  
  export default App;