import styled from 'styled-components';
import CasualLogo from '../../assets/img/casualLogo.png';

export const LoansContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  width: 100%;
  background: #000;
  overflow-y: auto;
  align-items: center;
`;
export const StyledBackground = styled.div`
  background-image: url(${CasualLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  display: flex;
  height: 100vh;
  width: 100%;
  border: 0px solid red;
  z-index: 0;
  opacity: 0.4;
`;
export const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  padding: 20px;
  padding-bottom: 100px;
  padding-top: 0px;
  color: #0C4B33;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin-top: 10px;
`;
export const Title = styled.h1`
  font-family: 'Trek', 'Roboto', sans-serif;
  color: #FFFFFF;
  text-align: center;
  font-size: 2.5em;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.6);
  margin-bottom: 20px;
`;
export const TaskList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
`;
/*
export const TaskCard = styled.div`
  background: linear-gradient(145deg, #0ac18e 0%, #2F7B54 100%);
  border: 2px solid #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  &:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  }
`;
export const TaskContent = styled.div`
  display: flex;
  gap: 20px;
`;
*/
export const QRSection = styled.div`
  background: white;
  padding: 5px;
  height: 150px;
  width: 200px;
  border: 0px solid red;
`;
export const TaskDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
/*
export const TaskTitle = styled.h2`
  color: #FFFFFF;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.6);
  margin: 0;
  font-size: 1.5em;
`;
export const DonationAmount = styled.div`
  font-size: 1.2em;
  color: #FFFFFF;
  font-weight: bold;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
`;
*/
export const WeightBarContainer = styled.div`
  width: 75%;
  height: 20px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #fff;
  border-radius: 5px;
  overflow: hidden;
  margin-left: 10px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
`;
interface WeightBarProps {
  width: number;
}
export const WeightBar = styled.div<WeightBarProps>`
  height: 100%;
  width: ${props => props.width}%;
  background: linear-gradient(90deg, #FF6B4A 0%, #FF8C4A 100%);
  transition: width 0.3s ease;
`;
//#ffffff 0%, #FF6B4A 50%, #FF8C4A 100%);
export const Address = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #eee;
  cursor: pointer;
  word-break: break-all;
  border: 0px solid #FFFFFF;
  border-radius: 4px;
  transition: all 0.1s ease;
  padding: 3px;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;
export const TotalDonations = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #eee;
  word-break: break-all;
  padding: 3px;
`;
export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;
export const AddTaskButton = styled.button`
  background: linear-gradient(90deg, #0ac18e 0%, #2F7B54 100%);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  border: 1px solid #FFFFFF;
  cursor: pointer;
  font-size: 1em;
  transition: all 0.3s ease;
  font-weight: bold;
  box-shadow: 0 2px 8px rgba(255, 107, 74, 0.3);
  &:hover {
    background: linear-gradient(90deg, #0dd89f 0%, #3fa16e 100%);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(255, 107, 74, 0.4);
  }
  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(255, 107, 74, 0.2);
  }
`;
export const TaskDescription = styled.p`
  color: #eee;
  margin: 0;
  padding: 5px;
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
`;
export const TotalDonatedRow = styled.div`
  color: #eee;
  font-size: 21px;
  font-weight: 600;
  gap: 5px;
  display: flex;
  flex-direction: row;
`;
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
`;
export const ModalTitle = styled.h2`
  color: #FFF;
  margin: 0;
  font-size: 1.5em;
`;
export const Input = styled.input`
  background: rgba(220, 220, 220, 1);
  border: 2px solid #0ac18e;
  padding: 10px;
  padding-bottom: 30px;
  border-radius: 5px;
  color: #000;
  width: 95%;
  font-size: 1em;
`;
export const TextArea = styled.textarea`
  background: rgba(220, 220, 220, 1);
  border: 2px solid #0ac18e;
  padding: 10px;
  padding-bottom: 30px;
  border-radius: 5px;
  color: #000;
  font-size: 1em;
  min-height: 100px;
  width: 95%;
  resize: vertical;

`;
export const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
`;
export const Button = styled.button`
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: all 0.3s ease;
`;
export const CancelButton = styled(Button)`
  background: #f95c15;
  opacity: 0.75;
  border: 1px solid #FFF;
  color: #FFF;
  &:hover {
    opacity: 1;
  }
`;
export const AddButton = styled(Button)`
  background: #0ac18e;
  opacity: 0.75;
  border: 1px solid #FFF;
  color: #fff;
  &:hover {
    opacity: 1;
  }
`;
export const PaymentInstructions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  text-align: center;
  h3 {
      margin: 0;
      color: #FCFC54;
  }
  p {
      margin: 5px 0;
  }
  small {
      color: #666;
      font-style: italic;
  }
`;
export const QRCodeWrapper = styled.div`
  background: white;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
`;
export const AddressWrapper = styled.div`
  background: rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 4px;
  font-size: 0.8em;
  word-break: break-all;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;
export const StyledBCHLogo = styled.img`
  vertical-align: middle;
  display: inline-block;
  height: 30px;
  width: 30px;
  margin: 0 5px;
  position: relative;
  top: 0px;
  border: 0px solid #FFFFFF;
  border-radius: 20px;
`;
export const InputWrapper = styled.div`
    position: relative;
    width: 100%;
`;
export const CharCount = styled.div`
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.6);
`;
export const AddUserButton = styled(AddTaskButton)`
    margin-right: 10px;
    background: #4A90E2;
    
    &:hover {
        background: #357ABD;
    }
`;
export const UserModalContent = styled(ModalContent)`
    max-width: 500px;
`;
export const XPubInput = styled(Input)`
    font-family: monospace;
    font-size: 12px;
    height: 60px;
    word-break: break-all;
`;
export const DeleteButton = styled.button<{ disabled?: boolean }>`
    background-color: ${props => props.disabled ? '#999' : '#ff4444'};
    color: white;
    width: 150px;
    border: 2px solid #fff;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    margin-top: 10px;
    font-size: 16px;
    transition: background-color 0.2s;

    &:hover {
        background-color: ${props => props.disabled ? '#999' : '#ff0000'};
    }
`;
export const StyledSelect = styled.select`
  appearance: none; // Removes default browser styling
  background: transparent;
  border: none;
  font-size: 1.8em;
  font-weight: 600;
  color: #eee;
  cursor: pointer;
  padding: 8px 24px 8px 8px;
  transition: color 0.2s;
  min-width: 300px;
  
  /* Custom dropdown arrow */
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23333%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.4-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0em top 50%;
  background-size: 0.65em auto;

  &:hover {
    color: #fff;
  }

  &:focus {
    outline: none;
    color: #fff;
  }

  /* Hide dropdown arrow in IE */
  &::-ms-expand {
    display: none;
  }

  option {
    background-color: #000;
    color: #eee;
    font-size: 0.7em;
    padding: 10px;
  }
`;
export const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  min-width: 200px;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 8px;
    right: 24px; // Account for the dropdown arrow
    height: 2px;
    background: linear-gradient(to right, #fff, transparent);
    opacity: 0.5;
  }

  &:hover::after {
    opacity: 0.9;
  }
`;
export const TaskCard = styled.div<{ $isExpanded: boolean }>`
  position: relative;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 10;

  ${props => props.$isExpanded && `
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  `}
`;
export const MinimizedContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-left: 15px;
    gap: 5px;
    width: 100%;
    min-height: 60px;
    height: auto;
`;
export const ExpandedContent = styled.div`
    padding-left: 15px;
    padding-right: 15px;
    border-top: 1px solid #eee;
    margin-top: 8px;
`;
// Update TaskContent to be simpler
export const TaskContent = styled.div`
  position: relative;
  width: 100%;
  border: 2px solid #0AC18E;
  border-radius: 10px;
  z-index: 10;
`;
// Update TaskTitle to work in minimized view
export const TaskTitle = styled.h3`
    margin: 0;
    flex: 1;
    font-size: 1.4em;
    color: #eee;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
`;
// Update DonationAmount for minimized view
export const DonationAmount = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.4em;
    color: #eee;
    font-weight: 600;
    white-space: nowrap;
`;
export const MinimizeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    gap: 10px;
`;
export const ExpandedWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
export const ExpandedWrapperColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
export const TaskBackground = styled.div`
  position: absolute;
  background: #000;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  border: 0px solid red;
  border-radius: 10px;
  z-index: 0;
`;
export const InfoSection = styled.div`
    width: 100%;
    margin-bottom: 20px;
    color: #eee;
`;
export const InfoHeader = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0px;
    font-size: 1em;
    font-weight: 600;
    color: #eee;
    border-bottom: 1px solid #0ac18e;
    &:hover {
        color: #0dd89f;
        border-bottom-color: #0dd89f;
    }
`;
export const InfoContent = styled.div<{ $isExpanded: boolean }>`
    max-height: ${props => props.$isExpanded ? '500px' : '0'};
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 0 0 5px 5px;
    padding: ${props => props.$isExpanded ? '0px 0px 0px 10px' : '0 10px'};
    line-height: 1.5;
`;
export const TabContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;
export const TabButton = styled.button<{ $isActive: boolean }>`
  padding: 8px 16px;
  border: 1px solid #fff;
  border-radius: 4px;
  background: ${props => props.$isActive ? '#0ac18e' : '#2d2d2d'};
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: ${props => props.$isActive ? '#2bcfa1' : '#004295'};
  }
`;